import { isLoggedIn } from '../login';
import { EventPayload } from '@jimdo/frontend-tracking';
import { Nullable } from '../utils';
import { Logo } from '../../../__generated__/graphql';
import { trackEvent } from '@jimdo/consent-management-tracking';

export type ModuleType =
  | 'app'
  | 'business-name'
  | 'category-suggestion'
  | 'checkout'
  | 'color-suggestion'
  | 'custom-color-suggestion'
  | 'custom-icon-suggestion'
  | 'download'
  | 'edit-business-name'
  | 'edit-business-name-button'
  | 'font-suggestion'
  | 'icon-suggestion'
  | 'layout-suggestion'
  | 'logo-preview'
  | 'logo-suggestion'
  | 'logo-website-split-screen-v2'
  | 'nav-bar'
  | 'preview'
  | 'preview-frame'
  | 'experiment'; // For experiments.

export type IdentifierType =
  | 'button'
  | 'buy-logo-cta'
  | 'change-font'
  | 'change-icon'
  | 'change-secondary-color'
  | 'changed-layout'
  | 'character-limit'
  | 'continue'
  | 'continue-button'
  | 'device'
  | 'info-cta'
  | 'link'
  | 'load-more'
  | 'logo-plus-website-cta'
  | 'logos-link'
  | 'nav-item'
  | 'package'
  | 'page'
  | 'primary-color'
  | 'search'
  | 'secondary-color'
  | 'start-free'
  | 'suggested-logo'
  | 'survey'
  | 'tos-checked'
  | 'fullstory'
  | 'download-free-btn'
  | 'logo-carousel'
  | 'google-signup'
  | 'facebook-signup'
  | 'apple-signup'
  | 'email-signup'
  | 'email-signup'
  | 'login'
  | 'download'
  | 'change-your-logo-link'
  | 'asset-slider-change'
  | 'open-more-info-modal'
  | 'open-more-info-modal'
  | 'open-signup-modal';

export type TriggerType =
  | 'change'
  | 'click'
  | 'error'
  | 'hide'
  | 'load'
  | 'mount'
  | 'mutation_error'
  | 'mutation_success'
  | 'show'
  | 'submit'
  | 'succeed'
  | 'toggle'
  | 'unload';

export interface TrackData {
  module: ModuleType;
  identifier: IdentifierType;
  trigger: TriggerType;
  data?: EventPayload & { analytics?: { ingest?: boolean; aggregate?: boolean } }; // extended to support [MixPanel integration](https://jimplan.atlassian.net/wiki/spaces/DnA/pages/4141645882/Tracking+Event+Standards+for+Mixpanel#Analytics-Features-through-Event-Metadata)
}

export const isUserTrackingAllowed = async () => {
  /* istanbul ignore next line */
  if (window.__jimdo__) {
    const isAllowed = await isLoggedIn();

    return {
      isAllowed,
    };
  }

  return {
    isAllowed: false,
  };
};

export async function track(trackData: TrackData) {
  trackEvent({
    action: trackData.identifier,
    label: trackData.trigger,
    payload: trackData.data,
    category: trackData.module,
  });
}

export const trackingFromLogo = (logo?: Nullable<Logo>): EventPayload => {
  if (!logo) {
    return {};
  }
  return {
    layout: logo.layout,
    icon: logo.icon?.id ?? null,
    primaryColor: logo.primaryColor,
    secondaryColor: logo.secondaryColor,
    font: logo.font,
  };
};

export const getModuleFromPathname = (pathname: string): ModuleType => {
  switch (pathname) {
    case '/font':
      return 'font-suggestion';

    case '/color':
      return 'color-suggestion';

    case '/layout':
      return 'layout-suggestion';

    case '/icon':
      return 'icon-suggestion';

    case '/preview':
      return 'preview';

    default:
      // shouldn't happen but makes the best out of a missing location
      return 'experiment';
  }
};
