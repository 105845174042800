import { useState, useRef, useCallback, useContext, ReactNode, PropsWithChildren } from 'react';
import { useResizeWindow } from '../../hooks/useResizeWindow/useResizeWindow';
import { FlexBox, IconButton, iconUser } from '@jimdo/ui';
import { ProfileMenu } from '../ProfileMenu/ProfileMenu';
import { useClickOutsideElement } from '../../hooks/useClickOutsideElement/useClickOutsideElement';
import { translations } from './ProfileLogin.translations';
import { useCustomTranslations } from '../../hooks/useCustomTranslations/useCustomTranslations';
import { ConfigContext } from '../../contexts/ConfigContext/ConfigContext';
import classNames from 'classnames';
import styles from './ProfileLogin.module.scss';
import ReactMarkdown from 'react-markdown';
import { routes } from '../../../config/routes';

interface Props {
  label: string;
  isLoggedIn: boolean;
}

interface ProfileMenuItemProps {
  href?: string;
  className?: string;
  children: ReactNode;
}

interface ProfileMenuItemNewTabProps extends ProfileMenuItemProps {
  openNewTab?: boolean;
  target?: '_blank' | '_self';
}

const ProfileMenuItem = ({ href, className, children }: PropsWithChildren<ProfileMenuItemProps>) => (
  <FlexBox
    component="a"
    alignItems="center"
    paddingLeft="xl"
    paddingRight="xl"
    href={href}
    className={classNames(styles.menuItem, className)}
  >
    {children}
  </FlexBox>
);

const ProfileMenuItemNewTab = ({
  href,
  className,
  children,
  target,
}: PropsWithChildren<ProfileMenuItemNewTabProps>) => (
  <FlexBox
    component="a"
    alignItems="center"
    paddingLeft="xl"
    paddingRight="xl"
    href={href}
    className={classNames(styles.menuItem, className)}
    target={target || '_blank'}
    rel="noopener noreferrer"
  >
    {children}
  </FlexBox>
);

export const ProfileLogin = ({ label, isLoggedIn }: PropsWithChildren<Props>) => {
  const isLargeScreen = useResizeWindow('big');
  const [showMenu, setShowMenu] = useState(false);
  const profileSelectorRef = useRef<HTMLDivElement | null>(null);
  const { logosUrl: DASH_LOGOS } = useContext(ConfigContext);
  const { loggedIn, loggedOut, termsOfUse } = useCustomTranslations(translations);

  const logoLink = routes.LOGIN + `?redirect=${encodeURIComponent(window.location.href)}`;
  useClickOutsideElement(
    profileSelectorRef,
    useCallback(() => setShowMenu(false), []),
  );

  const renderMenu = () =>
    isLoggedIn ? (
      <ProfileMenu className={styles.menu}>
        <ProfileMenuItemNewTab target="_self" href={DASH_LOGOS}>
          {loggedIn.myLogos}
        </ProfileMenuItemNewTab>
        <ReactMarkdown
          source={termsOfUse}
          renderers={{
            link: ProfileMenuItemNewTab,
            paragraph: ({ children }) => <>{children}</>,
          }}
        />
      </ProfileMenu>
    ) : (
      <ProfileMenu className={styles.menu}>
        <ProfileMenuItem href={logoLink}>{loggedOut.logIn}</ProfileMenuItem>
        <ReactMarkdown
          source={termsOfUse}
          renderers={{
            link: ProfileMenuItemNewTab,
            paragraph: ({ children }) => <>{children}</>,
          }}
        />
      </ProfileMenu>
    );

  return (
    <div className={styles.root} ref={profileSelectorRef}>
      <FlexBox alignItems="center" justifyContent="center" className={styles.wrapper}>
        <IconButton
          buttonStyle="primary"
          onClick={() => setShowMenu(!showMenu)}
          icon={iconUser}
          className={styles.profileButton}
          {...(isLargeScreen ? { label: label } : { ariaLabel: label })} // do not show tooltip on small screens
        />
      </FlexBox>
      {showMenu && renderMenu()}
    </div>
  );
};
