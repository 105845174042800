import styles from './PageHeader.module.scss';
import { Typography } from '@jimdo/ui';
import classNames from 'classnames';
import { PropsWithChildren } from 'react';

export interface Props {
  subHeadline?: string;
  children: string;
  className?: string;
  headlineClassName?: string;
}

export const PageHeader = ({
  children,
  subHeadline,
  className,
  headlineClassName,
  ...rest
}: PropsWithChildren<Props>) => (
  <div className={classNames(styles.root, className)} {...rest}>
    <Typography
      color="systemColorGrey-600"
      align="center"
      className={headlineClassName}
      variant="heading1"
      textTransform="uppercase"
      marginBottom="l"
    >
      {children}
    </Typography>
    {subHeadline && (
      <Typography variant="bodyDefault" align="center">
        {subHeadline}
      </Typography>
    )}
  </div>
);
