import { LayoutContent } from '../../../../common/ui/LayoutContent/LayoutContent';
import { PageHeader } from '../../../../common/ui/PageHeadline/PageHeader';
import { useCustomTranslations } from '../../../../common/hooks/useCustomTranslations/useCustomTranslations';
import { translations } from './BusinessName.translations';
import { Button, useStyles, FlexBox, Box } from '@jimdo/ui';
import { isTextInvalid } from '../../../../common/helpers/textUtils';
import { BusinessNameInput } from '../../../../common/ui/BusinessNameInput/BusinessNameInput';
import styles from './BusinessName.module.scss';
import { SyntheticEvent } from 'react';

interface BusinessNameProps {
  text: string;
  setText: (text: string) => void;
  onContinue: (e: SyntheticEvent<HTMLFormElement>) => void;
}

export const BusinessName = ({ text, setText, onContinue }: BusinessNameProps) => {
  const { subHeadline, headline, button } = useCustomTranslations(translations);
  const inputWrapperClasses = useStyles({ marginBottom: 'xxl' }, styles.inputOuterWrapper);

  const isInputInvalid = isTextInvalid(text);

  return (
    <LayoutContent>
      <FlexBox
        component="form"
        flexDirection="column"
        alignItems="center"
        className={styles.form}
        onSubmit={onContinue}
      >
        <PageHeader subHeadline={subHeadline}>{headline}</PageHeader>

        <Box
          component={BusinessNameInput}
          alignSelf="stretch"
          marginBottom="xxxl"
          marginRight="auto"
          marginLeft="auto"
          text={text}
          setText={setText}
          className={inputWrapperClasses}
        />

        <Button
          className={styles.button}
          type="submit"
          data-e2e="continue"
          disabled={isInputInvalid}
          buttonStyle="primary"
        >
          {button}
        </Button>
      </FlexBox>
    </LayoutContent>
  );
};
