import { useContext, useEffect, useMemo, useState } from 'react';
import { saveAs } from 'file-saver';
import { LogoStateContext } from '../../common/contexts/LogoStateContext/LogoStateContext';
import { useSaveLogoMutation } from '../../__generated__/graphql';
import { track, trackingFromLogo } from '../../common/helpers/tracking/tracking';
import { NonNullableProps } from '../../common/helpers/utils';
import { useLogoUIState } from '../../common/hooks/useLogoUIState/useLogoUIState';
import { logoIdFromCookie } from '../../common/helpers/cookies';
import { ConfigContext } from '../../common/contexts/ConfigContext/ConfigContext';
import { isMobileUserAgent } from '../../common/helpers/isMobileUserAgent';

export const DownloadV2 = () => {
  const { logo, setLogo } = useContext(LogoStateContext);
  const { logosUrl } = useContext(ConfigContext);
  const [isLoadedOnce, setIsLoadedOnce] = useState(false);
  const DOWNLOAD_URL_TEMPLATE = '/download_logo/zip/';
  const isMobileUser = useMemo(() => {
    return isMobileUserAgent(navigator.userAgent);
  }, []);
  const REDIRECT_TIMEOUT = useMemo(() => {
    return isMobileUser ? 2000 : 1000; // we want a longer time for the user on mobile to interact with the saveAs dialog
  }, [isMobileUser]);

  const logoUIState = useLogoUIState();

  const [saveLogo] = useSaveLogoMutation({
    variables: {
      logo: logo as NonNullableProps<typeof logo>,
      uiState: logoUIState,
    },
    onCompleted: (data) => {
      setLogo({
        ...(logo as NonNullableProps<typeof logo>),
        id: data.saveLogo,
      });

      logoIdFromCookie.logoId = data.saveLogo!;
    },
  });

  useEffect(() => {
    track({
      trigger: 'show',
      module: 'download',
      identifier: 'page',
      data: {
        version: 2,
      },
    });
  }, []);

  useEffect(() => {
    const asyncFn = async () => {
      const result = await saveLogo();
      const logoId = result.data?.saveLogo;

      // redirect user to new logos page in dash and start download in parallel
      saveAs(`${DOWNLOAD_URL_TEMPLATE}${logoId}`);
      /* istanbul ignore next */
      track({
        trigger: 'load',
        module: 'download',
        identifier: 'button',
        data: {
          logo: trackingFromLogo(logo),
        },
      });
      setTimeout(() => {
        window.location.assign(logosUrl);
      }, REDIRECT_TIMEOUT);
    };
    if (!isLoadedOnce) {
      asyncFn();
      setIsLoadedOnce(true);
    }
  }, [REDIRECT_TIMEOUT, isLoadedOnce, logo, logosUrl, saveLogo]);

  return null;
};
