import data from './supportedCharset.json';

export const clearWhiteSpaces = (text: string, allowOneWhiteSpaceAtTheEnd?: boolean): string => {
  let clean = text.trim().replace(/\s+/g, ' ');

  const endsWithWhiteSpace = text.charAt(text.length - 1) === ' ';
  if (allowOneWhiteSpaceAtTheEnd && endsWithWhiteSpace && clean.length > 0) {
    clean += ' ';
  }
  return clean;
};

export const isCharSupported = (char: string): boolean => data.supportedCharset.includes(char);

export const containsUnsupportedChars = (text: string): boolean => {
  return !text.split('').every(isCharSupported);
};

export const isTextInvalid = (text: string): boolean => {
  const trimmed = clearWhiteSpaces(text);
  const isEmpty = trimmed.length === 0;
  return isEmpty || containsUnsupportedChars(trimmed);
};
