import { QueryHookOptions, useQuery, QueryResult } from '@apollo/client';
import { useErrorLoggingContext } from '../../contexts/ErrorLoggingContext/ErrorLoggingContext';
import { DocumentNode } from 'graphql';
import type { OperationVariables } from '@apollo/client';
import { useContext } from 'react';
import { SnackbarContext } from '../../contexts/SnackbarContext/SnackbarContext';
import { translations } from '../../../common.translations';
import { useCustomTranslations } from '../useCustomTranslations/useCustomTranslations';

interface QueryResultUnsuccessful<D, V extends OperationVariables> extends QueryResult<D, V> {
  success: false;
  data: undefined;
}

interface QueryResultSuccessful<D, V extends OperationVariables> extends QueryResult<D, V> {
  success: true;
  data: D;
}

type QueryResultUnion<D, V extends OperationVariables> = QueryResultUnsuccessful<D, V> | QueryResultSuccessful<D, V>;

export interface Options<D, V extends OperationVariables> extends QueryHookOptions<D, V> {
  customErrorMessage?: string;
  noSnackbarOnError?: boolean;
}

export function useCustomQuery<D, V extends OperationVariables>(
  query: DocumentNode,
  options: Options<D, V> = {},
): QueryResultUnion<D, V> {
  const { report } = useErrorLoggingContext();
  const { pushMessage } = useContext(SnackbarContext);
  const { queryErrorGeneral } = useCustomTranslations(translations);

  const { customErrorMessage, noSnackbarOnError, onError, ...queryHookOptions } = options;

  const queryHookResult = useQuery<D, V>(query, {
    ...queryHookOptions,
    notifyOnNetworkStatusChange: true,
    onError: (error) => {
      /* istanbul ignore next line */
      report(error || new Error('No data received'), {
        metaData: {
          data,
          query,
          variables: options.variables,
        },
      });

      if (onError) {
        onError(error);
      }

      if (!noSnackbarOnError) {
        pushMessage({ message: customErrorMessage || queryErrorGeneral, type: 'danger' });
      }
    },
  });
  const { data, error, networkStatus } = queryHookResult;

  if (networkStatus === 1 || (!error && !data)) {
    return {
      ...queryHookResult,
      success: false,
      data: undefined,
    };
  }

  if (error || !data) {
    return {
      ...queryHookResult,
      success: false,
      data: undefined,
    };
  }

  return {
    ...queryHookResult,
    success: true,
    data,
  };
}
