import { Snackbar, SnackbarProps, FlexBox } from '@jimdo/ui';
import styles from './SnackbarList.module.scss';
import { ReactNode } from 'react';

interface SnackbarListProps {
  onClose: (m: SnackbarMessageWithTiming) => void;
  messages: readonly SnackbarMessageWithTiming[];
}

export interface SnackbarMessageWithTiming extends SnackbarMessage {
  timestamp: number;
}

export interface SnackbarMessage extends SnackbarProps {
  message: ReactNode;
}

export const SnackbarList = ({ messages, onClose }: SnackbarListProps) => (
  <FlexBox flexDirection="column">
    {!!messages.length && (
      <FlexBox flexDirection="column" alignItems="center">
        <FlexBox
          flexDirection="column"
          alignItems="center"
          alignSelf="center"
          paddingLeft="xs"
          paddingRight="xs"
          className={styles.list}
        >
          {messages.map((m) => (
            <Snackbar
              key={`${m.message}-${m.timestamp}-${m.type}`}
              type={m.type}
              className="SnackbarContainerItem"
              onClose={() => onClose(m)}
            >
              {m.message}
            </Snackbar>
          ))}
        </FlexBox>
      </FlexBox>
    )}
  </FlexBox>
);
