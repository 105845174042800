import { getDpsCookieNames, init as initConsent } from '@jimdo/consent-management';
import {
  getAnonId,
  getUserAccountId,
  init as initTracking,
  setRichTracking,
  withConsentOptIn,
} from '@jimdo/consent-management-tracking';

import { getTrackingEndpoint, Tracking } from '@jimdo/frontend-tracking';

export const loadConsentManagementBanner = () => {
  /* istanbul ignore next */
  const t = Tracking({
    endpoint: getTrackingEndpoint(window.location.hostname),
    env: 'logo',
    anonId: getAnonId,
    userAccountId: () => getUserAccountId() || '',
    isAffiliate: () => false,
    logToConsole: process.env.NODE_ENV === 'development',
  });

  withConsentOptIn(setRichTracking(t));

  initTracking({
    getDpsCookieNames: getDpsCookieNames,
  });

  initConsent();
};
