import { ModalTwoColumns, List, ListItem, Typography } from '@jimdo/ui';
import styles from './LogoInformationModal.module.scss';
import brandingMockup from './assets/branding-identity-mockup.jpg';
import { translations } from './LogoInformationModal.translations';
import { PropsWithChildren } from 'react';
import { useCustomTranslations } from '../../hooks/useCustomTranslations/useCustomTranslations';

interface Props {
  isOpen: boolean;
  onCloseClick: () => void;
}

export const LogoInformationModal = ({ onCloseClick, isOpen }: PropsWithChildren<Props>) => {
  const t = useCustomTranslations(translations);

  const rightArea = <img className={styles.image} src={brandingMockup} alt="" />;

  const leftArea = (
    <div className={styles.leftArea}>
      <Typography variant="heading3" marginBottom="m">
        {t.title}
      </Typography>

      <Typography variant="bodyDefault" marginBottom="l">
        {t.description}
      </Typography>

      <Typography variant="heading3" marginBottom="m">
        {t.listTitle}
      </Typography>

      <List className={styles.list}>
        <ListItem
          marginBottom="l"
          className={styles.listItem}
          text={
            <>
              <Typography variant="bodyDefault" fontWeight="bold" marginBottom="zero" component="span">
                {t.listItem.one.header}
              </Typography>
              <Typography variant="bodyDefault" component="span" marginBottom="zero">
                {t.listItem.one.content}
              </Typography>
            </>
          }
        />
        <ListItem
          marginBottom="l"
          className={styles.listItem}
          text={
            <>
              <Typography variant="bodyDefault" fontWeight="bold" marginBottom="zero" component="span">
                {t.listItem.two.header}
              </Typography>
              <Typography variant="bodyDefault" component="span" marginBottom="zero">
                {t.listItem.two.content}
              </Typography>
            </>
          }
        />
        <ListItem
          marginBottom="l"
          className={styles.listItem}
          text={
            <>
              <Typography variant="bodyDefault" fontWeight="bold" marginBottom="zero" component="span">
                {t.listItem.three.header}
              </Typography>
              <Typography variant="bodyDefault" component="span" marginBottom="zero">
                {t.listItem.three.content}
              </Typography>
            </>
          }
        />
        <ListItem
          marginBottom="l"
          className={styles.listItem}
          text={
            <>
              <Typography variant="bodyDefault" fontWeight="bold" marginBottom="zero" component="span">
                {t.listItem.four.header}
              </Typography>
              <Typography variant="bodyDefault" component="span" marginBottom="zero">
                {t.listItem.four.content}
              </Typography>
            </>
          }
        />
      </List>
    </div>
  );

  return (
    <ModalTwoColumns
      isOpen={isOpen}
      className={styles.root}
      leftSide={leftArea}
      rightSide={rightArea}
      onCloseClick={onCloseClick}
    />
  );
};
