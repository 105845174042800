export const translations = (t: TranslationFunction) => ({
  title: t('view.preview.logoInformationModal.headline', {
    defaultValue: 'All the professional logo files you need',
  }),
  description: t('view.preview.logoInformationModal.description', {
    defaultValue: `We'll provide all the high-quality file sizes you need for your website, print and online formats.`,
  }),
  listTitle: t('view.preview.logoInformationModal.listTitle', {
    defaultValue: `What's included?`,
  }),
  listItem: {
    one: {
      header: t('view.preview.logoInformationModal.listItem.one.header', {
        defaultValue: 'Full commercial usage rights',
      }),
      content: t('view.preview.logoInformationModal.listItem.one.content', {
        defaultValue: 'Commercial and non-commercial rights to use your logo online or offline.',
      }),
    },
    two: {
      header: t('view.preview.logoInformationModal.listItem.two.header', {
        defaultValue: `3 high-quality logo files (PNG) including a white version`,
      }),
      content: t('view.preview.logoInformationModal.listItem.two.content', {
        defaultValue: `The perfect logo file for your website or anywhere online.`,
      }),
    },
    three: {
      header: t('view.preview.logoInformationModal.listItem.three.header', {
        defaultValue: `Resizable logo file (SVG)`,
      }),
      content: t('view.preview.logoInformationModal.listItem.three.content', {
        defaultValue: `Print your high-quality logo in any size on business cards, posters or flyers.`,
      }),
    },
    four: {
      header: t('view.preview.logoInformationModal.listItem.four.header', {
        defaultValue: `2 small, high-quality sized logo files (PNG)`,
      }),
      content: t('view.preview.logoInformationModal.listItem.four.content', {
        defaultValue: `Picture perfect files for your social media pages.`,
      }),
    },
  },
});
