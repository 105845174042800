export const translations = (t: TranslationFunction) => ({
  inputPlaceholder: t('view.businessName.input.placeholder', {
    defaultValue: 'Type your business name',
  }),
  clearInput: t('view.businessName.input.clearButton', {
    defaultValue: 'Clear',
  }),
  invalidCharsErrorMessage: t('view.businessName.input.invalidCharsErrorMessage', {
    defaultValue: 'Please remove any special characters and try again.',
  }),
  inputTitle: t('view.businessName.input.title', {
    defaultValue: 'Input field to set your business name',
  }),
  tooltip: t('view.businessName.input.tooltip', {
    defaultValue: 'For best results, use a short business name with no tag line',
  }),
});
