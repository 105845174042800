import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { routes } from './config/routes';
import { AuthenticationContainer } from './common/containers/AuthenticationContainer/AuthenticationContainer';
import { BusinessName } from './views/BusinessName/BusinessName';
import { App } from './App';
import { CategorySuggestionAnimationWrapper } from './views/CategorySuggestionAnimationWrapper/CategorySuggestionAnimationWrapper';
import { lazy } from 'react';
import { DownloadV2 } from './views/DownloadV2/DownloadV2';

const OuterFrame = lazy(async () => ({
  default: (await import(/* webpackPrefetch: true */ './common/containers/OuterFrame/OuterFrame')).OuterFrame,
}));

const ErrorPage = lazy(async () => ({
  default: (await import(/* webpackPrefetch: true */ './views/ErrorPage/ErrorPage')).ErrorPage,
}));

const Edit = lazy(async () => ({
  default: (await import(/* webpackPrefetch: true */ './views/Edit/Edit')).Edit,
}));

export const AppRouter = () => {
  const router = createBrowserRouter([
    {
      element: <App />,
      children: [
        {
          path: routes.ROOT,
          element: <AuthenticationContainer element={<BusinessName />} />,
        },
        {
          path: routes.CATEGORY,
          element: <CategorySuggestionAnimationWrapper />,
        },
        {
          path: routes.ICON,
          element: <OuterFrame />,
        },
        {
          path: routes.LAYOUT,
          element: <OuterFrame />,
        },
        {
          path: routes.FONT,
          element: <OuterFrame />,
        },
        {
          path: routes.COLOR,
          element: <OuterFrame />,
        },
        {
          path: routes.EDIT,
          element: <AuthenticationContainer element={<Edit />} />,
        },
        {
          path: routes.DOWNLOAD,
          element: <AuthenticationContainer element={<DownloadV2 />} />,
        },
        {
          path: '*',
          element: <ErrorPage />,
        },
      ],
    },
  ]);

  return <RouterProvider router={router} />;
};
