import * as cookies from '../cookies';
import { getDataLayer } from './dataLayers';

export async function trackPageView(pathname: string) {
  const gtmDataLayer = getDataLayer('dataLayer');
  gtmDataLayer.push({
    event: 'virtpath',
    virtPath: pathname,
  });
}

export async function trackPageMetaData(pathname: string) {
  const userAccountId = cookies.getUserAccountIdFromCookie();
  const gtmDataLayer = getDataLayer('dataLayer');

  if (gtmDataLayer) {
    gtmDataLayer.push({
      event: 'Page Meta',
      page: pathname,
      uiUserId: userAccountId,
    });
  }
}
