import { MutableRefObject, useEffect } from 'react';

/**
 * useClickOutsideElement helps execute a callback when clicked
 * outside of the target element and its children.
 * Adapted to TS from: https://usehooks.com/useOnClickOutside/
 */
export function useClickOutsideElement<T extends HTMLElement | null>(
  ref: MutableRefObject<T>,
  handler: (event: MouseEvent | TouchEvent) => void,
) {
  useEffect(() => {
    const listener = (event: MouseEvent | TouchEvent) => {
      if (!ref.current || ref.current.contains(event.target as Node)) {
        return;
      }
      handler(event);
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler]);
}
