import { SnackbarContext } from '../../contexts/SnackbarContext/SnackbarContext';
import { PropsWithChildren, useEffect, useState } from 'react';
import { useInterval } from '../../hooks/useInterval/useInterval';
import { SnackbarMessageWithTiming, SnackbarMessage, SnackbarList } from '../../ui/SnackbarList/SnackbarList';

export const DEFAULT_MESSAGE_TIMEOUT = 12000;
export const DEFAULT_REMOVAL_INTERVAL = 1000;

/**
 * Snackbar Container
 *
 * @description
 * Handles visibility of snackbars. Through SnackbarContext.Consumer it is
 * possible to add new messages to a list of snackbars. Snackbars with the same message will
 * get filtered. Snackbars are terminated automatically after a specific time=`DEFAULT_MESSAGE_TIMEOUT`.
 * Removing snackbars are handled with an interval=`DEFAULT_REMOVAL_INTERVAL`.
 */
export const SnackbarContainer = ({ children }: PropsWithChildren<unknown>) => {
  const [messages, setMessages] = useState<SnackbarMessageWithTiming[]>([]);
  const [messagesToDelete, setMessagesToDelete] = useState<SnackbarMessageWithTiming[]>([]);

  const isMessageExistent = (newMessage: SnackbarMessage) =>
    messages.find((message) => message.message === newMessage.message) !== undefined;

  const messageNotTimedOut = (message: SnackbarMessageWithTiming) => {
    const now = new Date().getTime();
    const visibleUntil = message.timestamp + DEFAULT_MESSAGE_TIMEOUT;

    return visibleUntil >= now;
  };

  const pushMessage = (newMessage: SnackbarMessage) => {
    if (isMessageExistent(newMessage)) {
      return;
    }

    const message = { ...newMessage, timestamp: new Date().getTime() };
    setMessages((messages) => [...messages, message]);
  };

  useEffect(() => {
    if (messagesToDelete.length) {
      setMessages((messages) => {
        const newMessages = messages.filter((message) => !messagesToDelete.includes(message));
        return newMessages;
      });
      setMessagesToDelete([]);
    }
  }, [messages, messagesToDelete, messagesToDelete.length]);

  useInterval(
    () => {
      setMessages((messages) => {
        if (messages.length) {
          const newMessages = messages.filter(messageNotTimedOut);

          if (newMessages.length !== messages.length) {
            return newMessages;
          }
        }

        return messages;
      });
    },
    messages.length ? DEFAULT_REMOVAL_INTERVAL : null,
  );

  return (
    <SnackbarContext.Provider value={{ pushMessage }}>
      <SnackbarList messages={messages} onClose={(m) => setMessagesToDelete([...messagesToDelete, m])} />
      {children}
    </SnackbarContext.Provider>
  );
};
