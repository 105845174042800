import { useRef } from 'react';

/**
 * Allows a html element to be focused.
 * Based on https://stackoverflow.com/a/54159564/1128216 and
 * https://gist.github.com/carpben/de968e377cbac0ffbdefe1ab56237573
 */
export function useFocus<T extends HTMLElement>() {
  const htmlElRef = useRef<T | null>(null);
  const setFocus = () => {
    if (htmlElRef.current !== null) {
      htmlElRef.current.focus();
    }
  };

  return [htmlElRef, setFocus] as const;
}
