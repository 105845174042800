import { LoadingContext } from './LoadingContext';
import { PropsWithChildren, useCallback, useState } from 'react';

export const LoadingProvider = ({ children }: PropsWithChildren<unknown>) => {
  const [loadingStack, setLoadingStack] = useState<string[]>([]);

  const setLoading = useCallback((loadingID: string, isLoading: boolean) => {
    setLoadingStack((loadingStack) => {
      const index = loadingStack.indexOf(loadingID);

      if ((!isLoading && index === -1) || (isLoading && index > -1)) {
        return loadingStack;
      }

      return isLoading
        ? [...loadingStack, loadingID]
        : [...loadingStack.slice(0, index), ...loadingStack.slice(index + 1)];
    });
  }, []);

  return (
    <LoadingContext.Provider
      value={{
        isLoading: loadingStack.length > 0,
        setLoading,
      }}
    >
      {children}
    </LoadingContext.Provider>
  );
};
