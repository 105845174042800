import { lazy, Suspense, useEffect, useRef, useState } from 'react';
import { ApolloProvider } from '@apollo/client';
import { apolloClient } from './config/apollo';
import { ErrorLoggingContextProvider } from './common/contexts/ErrorLoggingContext/ErrorLoggingContextProvider';
import { PageViewTracker } from './common/containers/PageViewTracker/PageViewTracker';
import { TrackingContextProvider } from './common/contexts/TrackingContext/TrackingContextProvider';
import { SnackbarContainer } from './common/containers/SnackbarContainer/SnackbarContainer';
import { routes } from './config/routes';
import { LogoStateContextProvider } from './common/contexts/LogoStateContext/LogoStateContextProvider';
import { useGlobal_ConfigurationQuery } from './__generated__/graphql';
import { ConfigContext } from './common/contexts/ConfigContext/ConfigContext';
import { RolloutProvider } from './common/contexts/RolloutContext/RolloutProvider';
import { stripTypeName } from './common/helpers/utils';
import { EditBusinessNameModalContextProvider } from './common/contexts/EditBusinessNameModalContext/EditBusinessNameModalContextProvider';
import { Topbar } from './common/containers/Topbar/Topbar';
import { logoIdFromCookie } from './common/helpers/cookies';
import { track } from './common/helpers/tracking/tracking';
import { LoadingProvider } from './common/contexts/LoadingContext/LoadingProvider';
import { loadConsentManagementBanner } from './common/helpers/tracking/loadConsentManagementBanner';
import { i18n } from './config/i18n';
import { Outlet } from 'react-router-dom';

const ErrorPage = lazy(async () => ({
  default: (await import(/* webpackPrefetch: true */ './views/ErrorPage/ErrorPage')).ErrorPage,
}));

const initTracking = () => {
  try {
    loadConsentManagementBanner();
  } catch (e) {
    console.warn('Unable to load consent management banner');
  }
};

const AppContent = () => {
  const queryResult = useGlobal_ConfigurationQuery({
    onCompleted(data) {
      if (data.user?.language) {
        i18n.changeLanguage(data.user.language);
      }
    },
  });

  const [_queryResult, setQueryResult] = useState<typeof queryResult>(queryResult);
  const isiOS =
    (/iPad|iPhone|iPod/.test(navigator.platform) ||
      (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) &&
    //@ts-ignore
    !window.MSStream;
  const isAndroid = /Android/i.test(navigator.userAgent);
  const sessionStartTimeRef = useRef(Date.now());

  useEffect(() => {
    if (queryResult.data && !_queryResult.data) {
      setQueryResult(queryResult);
    }
  }, [_queryResult.data, queryResult, queryResult.data]);

  /* istanbul ignore next */
  useEffect(() => {
    initTracking();
  }, []);

  useEffect(() => {
    /* istanbul ignore next */
    if (queryResult.success) {
      track({
        trigger: 'load',
        module: 'app',
        identifier: 'device',
        data: {
          deviceType: isiOS ? 'ios' : isAndroid ? 'android' : 'desktop',
        },
      });
    }
  }, [queryResult.success, isiOS, isAndroid]);

  const handleUnloadEvent = () => {
    /* istanbul ignore next */
    track({
      trigger: 'unload',
      module: 'app',
      identifier: 'device',
      data: {
        sessionTimeMs: Date.now() - sessionStartTimeRef.current,
      },
    });
  };

  useEffect(() => {
    if (queryResult.success) {
      const isFullstoryEnabled = queryResult.data.rollouts.crossProjectFlags.dolphinFullstory;
      track({
        trigger: 'load',
        module: 'app',
        identifier: 'fullstory',
        data: {
          isFullstoryEnabled,
        },
      });
    }
  }, [queryResult.data, queryResult.success]);

  useEffect(() => {
    window.addEventListener('beforeunload', handleUnloadEvent);

    return () => window.removeEventListener('beforeunload', handleUnloadEvent);
  }, []);

  if (!_queryResult.loading && !_queryResult.success) {
    return <ErrorPage />;
  }

  if (!_queryResult.success) {
    return null;
  }

  if (window.location.pathname === routes.ROOT) {
    logoIdFromCookie.clear();
  }

  return (
    <ConfigContext.Provider value={_queryResult.data?.config}>
      <RolloutProvider rollouts={stripTypeName(_queryResult.data?.rollouts)}>
        <LogoStateContextProvider>
          <EditBusinessNameModalContextProvider>
            <Topbar />
            <Suspense fallback={null}>
              <Outlet />
              <PageViewTracker />
            </Suspense>
          </EditBusinessNameModalContextProvider>
        </LogoStateContextProvider>
      </RolloutProvider>
    </ConfigContext.Provider>
  );
};

export const App = () => {
  return (
    <Suspense fallback={null}>
      <ErrorLoggingContextProvider>
        <ApolloProvider client={apolloClient}>
          <LoadingProvider>
            <SnackbarContainer>
              <TrackingContextProvider>
                <AppContent />
              </TrackingContextProvider>
            </SnackbarContainer>
          </LoadingProvider>
        </ApolloProvider>
      </ErrorLoggingContextProvider>
    </Suspense>
  );
};
