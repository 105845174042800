export const routes = {
  ROOT: '/',
  CATEGORY: '/category',
  ICON: '/icon',
  LAYOUT: '/layout',
  COLOR: '/color',
  FONT: '/font',
  DOWNLOAD: '/download',
  LOGO_WEBSITE_SPLIT: '/logo-website-split',
  EDIT: '/edit/:logoId',
  LOGIN: '/login',
  LOGOUT: '/logout',
  CHECKOUT: '/checkout',
  LAUNCH_DARKLY_GATEWAY: '/next',
};
