/* istanbul ignore file */
import jsCookies from 'js-cookie';
import * as Cookies from '@jimdo/jimdo-cookies';

const COOKIE_NAME_COOKIE_ID = 'shd';
const COOKIE_NAME_USER_ACCOUNT_ID = 'user_account_id';
const COOKIE_NAME_LOGO_ID = 'logo_id';

export function getUserAccountIdFromCookie() {
  return jsCookies.get(COOKIE_NAME_USER_ACCOUNT_ID) || '';
}

export function getCookieIdFromCookie() {
  return jsCookies.get(COOKIE_NAME_COOKIE_ID) || '';
}

export const logoIdFromCookie = {
  get logoId() {
    return jsCookies.get(COOKIE_NAME_LOGO_ID) || null;
  },
  set logoId(id: string | null) {
    id && Cookies.set(COOKIE_NAME_LOGO_ID, id);
  },
  clear: () => {
    jsCookies.remove(COOKIE_NAME_LOGO_ID);
  },
};
