import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export function useCustomTranslations<T>(translations: (t: TranslationFunction) => T): T {
  const { t } = useTranslation();

  return useMemo(() => {
    return translations(t);
  }, [t, translations]);
}
