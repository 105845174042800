import { PropsWithChildren } from 'react';
import { ErrorLoggingContext } from './ErrorLoggingContext';
import { ReportErrorOptions } from './ErrorLoggingContext';

export const ErrorLoggingContextProvider = ({ children }: PropsWithChildren<unknown>) => {
  const report = (error: Error, options: ReportErrorOptions) => {
    if (process.env.NODE_ENV === 'test') {
      return;
    }

    /* istanbul ignore next line */
    if (process.env.NODE_ENV === 'development') {
      // eslint-disable-next-line no-console
      console.log('report error', error, options);
      return;
    }
  };

  return (
    <ErrorLoggingContext.Provider
      value={{
        report,
      }}
    >
      {children}
    </ErrorLoggingContext.Provider>
  );
};
