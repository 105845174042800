export const translations = (t: TranslationFunction) => ({
  headline: t('view.businessName.headline', {
    defaultValue: "What's your business name?",
  }),
  subHeadline: t('view.businessName.subHeadline', {
    defaultValue: "We'll incorporate your company name in the logo.",
  }),
  inputPlaceholder: t('view.businessName.input.placeholder', {
    defaultValue: 'Type your business name',
  }),
  button: t('view.businessName.submitButton', {
    defaultValue: 'Continue',
  }),
  clearInput: t('view.businessName.input.clearButton', {
    defaultValue: 'Clear',
  }),
  invalidCharsErrorMessage: t('view.businessName.input.invalidCharsErrorMessage', {
    defaultValue: 'Please remove any special characters and try again.',
  }),
});
