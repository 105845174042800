import { useCustomQuery, Options } from './common/hooks/useCustomQuery/useCustomQuery';
import { MutationHookOptions as ReactHooksMutationHookOptions } from '@apollo/client';
import { useCustomLazyQuery, Options as LazyOptions } from './common/hooks/useCustomLazyQuery/useCustomLazyQuery';
import type { OperationVariables } from '@apollo/client';

export { useMutation, useSubscription } from '@apollo/client';

export const useQuery = useCustomQuery;
export const useLazyQuery = useCustomLazyQuery;

export type QueryHookOptions<D, V extends OperationVariables> = Options<D, V>;
export type LazyQueryHookOptions<D, V extends OperationVariables> = LazyOptions<D, V>;
export type MutationHookOptions<D, V extends OperationVariables> = ReactHooksMutationHookOptions<D, V>;
