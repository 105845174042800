import { LogoColored, FlexBox, Box } from '@jimdo/ui';
import styles from './Topbar.module.scss';
import { useResizeWindow } from '../../hooks/useResizeWindow/useResizeWindow';
import { PropsWithChildren, ReactNode } from 'react';

interface Props {
  leftElement?: ReactNode;
  rightElement?: ReactNode;
}

export const Topbar = ({ leftElement, rightElement }: PropsWithChildren<Props>) => {
  const isMobile = useResizeWindow('small');

  return (
    <Box className={styles.root}>
      <Box className={styles.leftElement}>{leftElement}</Box>
      <FlexBox alignItems="center" alignSelf="center" className={styles.logoWrapper}>
        <LogoColored className={styles.logo} />
      </FlexBox>

      {/* Actions currently only exist for mobile users */}
      {isMobile && <Box className={styles.rightElement}>{rightElement}</Box>}
    </Box>
  );
};
