import { useState, useEffect, useRef, useCallback, PropsWithChildren } from 'react';
import isEqual from 'lodash.isequal';
import { LogoStateContext, Colors, Icon, LogoOnlyOrPlusWebsiteOption } from './LogoStateContext';
import { Style, useIconLazyQuery, Font, Layout } from '../../../__generated__/graphql';
import { stripTypeName } from '../../helpers/utils';
import { logoIdFromCookie } from '../../helpers/cookies';
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';

interface ParsedSearch {
  text: string;
  icon: string;
  primaryColor: string;
  secondaryColor: string;
  font: Font;
  layout: Layout;
  seed: number;
  suggestionsPage: number;
  suggestedLayout: Layout;
  suggestedColors: Colors;
  suggestedIcon: string;
  suggestedStyle: Style;
  customColors: Colors;
  style: Style;
  iconSearchQuery: string;
  hasIconSearchResults: boolean;
  id: string;
  logoOnlyOrPlusWebsite: LogoOnlyOrPlusWebsiteOption;
  websiteId: string;
  category?: string;
  categoryGroup?: string;
  categorySearch?: string;
  isCustomCategory?: true | null;
  jimdoPurpose?: 'website' | 'onlineStore' | 'logoOnly';
}

type WithNull<T> = {
  [P in keyof T]: T[P] | null;
};

type ParsedSearchWithNull = WithNull<ParsedSearch>;

export const LogoStateContextProvider = ({ children }: PropsWithChildren<unknown>) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const originalQueryParams = useRef(searchParams);

  const parsedSearch = useRef<{
    [key in keyof ParsedSearchWithNull]?: ParsedSearchWithNull[key];
  }>({
    text: searchParams.get('text'),
    primaryColor: searchParams.get('primaryColor'),
    secondaryColor: searchParams.get('secondaryColor'),
    id: searchParams.get('id'),
    font: searchParams.get('font') as ParsedSearchWithNull['font'],
    seed: Number(searchParams.get('seed')),
    suggestionsPage: Number(searchParams.get('suggestionsPage')),
    suggestedLayout: searchParams.get('suggestedLayout') as ParsedSearchWithNull['suggestedLayout'],
    layout: searchParams.get('layout') as ParsedSearchWithNull['layout'],
    style: searchParams.get('style') as ParsedSearchWithNull['style'],
    suggestedColors: (searchParams.getAll('suggestedColors').length === 2
      ? searchParams.getAll('suggestedColors')
      : null) as ParsedSearchWithNull['suggestedColors'],
    iconSearchQuery: searchParams.get('iconSearchQuery'),
    hasIconSearchResults: searchParams.get('hasIconSearchResults') === 'true',
    category: searchParams.get('category'),
    categoryGroup: searchParams.get('categoryGroup'),
    categorySearch: searchParams.get('categorySearch'),
    isCustomCategory: searchParams.get('isCustomCategory') === 'true' ? true : null,
    suggestedStyle: searchParams.get('suggestedStyle') as ParsedSearchWithNull['suggestedStyle'],
    customColors: (searchParams.getAll('customColors').length === 2
      ? searchParams.getAll('customColors')
      : null) as ParsedSearchWithNull['customColors'],
    logoOnlyOrPlusWebsite: searchParams.get('logoOnlyOrPlusWebsite') as ParsedSearchWithNull['logoOnlyOrPlusWebsite'],
    websiteId: searchParams.get('websiteId'),
    icon: searchParams.get('icon'),
    suggestedIcon: searchParams.get('suggestedIcon'),
    jimdoPurpose: searchParams.get('jimdoPurpose') as ParsedSearchWithNull['jimdoPurpose'],
  });

  const [text, setText] = useState<ParsedSearchWithNull['text']>(
    parsedSearch.current.text ? String(parsedSearch.current.text) : null,
  );
  const [primaryColor, setPrimaryColor] = useState<ParsedSearchWithNull['primaryColor']>(
    parsedSearch.current.primaryColor || null,
  );
  const [secondaryColor, setSecondaryColor] = useState<ParsedSearchWithNull['secondaryColor']>(
    parsedSearch.current.secondaryColor || null,
  );
  const [id, setId] = useState<ParsedSearchWithNull['id']>(parsedSearch.current.id || logoIdFromCookie.logoId);
  const [font, setFont] = useState<ParsedSearchWithNull['font']>(parsedSearch.current.font || null);
  const [seed, setSeed] = useState<ParsedSearch['seed']>(
    parsedSearch.current.seed || parseInt(String(Math.random() * 1000000)),
  );
  const [suggestionsPage, setSuggestionsPage] = useState<ParsedSearch['suggestionsPage']>(
    parsedSearch.current.suggestionsPage || 1,
  );
  const [suggestedLayout, setSuggestedLayout] = useState<ParsedSearchWithNull['suggestedLayout']>(
    parsedSearch.current.suggestedLayout || null,
  );
  const [layout, setLayout] = useState<ParsedSearchWithNull['layout']>(parsedSearch.current.layout || null);
  const [style, setStyle] = useState<ParsedSearchWithNull['style']>(parsedSearch.current.style || null);
  const [suggestedColors, setSuggestedColors] = useState<ParsedSearchWithNull['suggestedColors']>(
    parsedSearch.current.suggestedColors || null,
  );

  const [icon, setIcon] = useState<Icon | null>(null);
  const [suggestedIcon, setSuggestedIcon] = useState<Icon | null>(null);

  const [iconSearchQuery, setIconSearchQuery] = useState<ParsedSearchWithNull['iconSearchQuery']>(
    parsedSearch.current.iconSearchQuery || null,
  );
  const [hasIconSearchResults, setHasIconSearchResults] = useState<ParsedSearchWithNull['hasIconSearchResults']>(
    !!parsedSearch.current.hasIconSearchResults || null,
  );
  const [category, setCategory] = useState<ParsedSearchWithNull['category']>(parsedSearch.current.category);
  const [categoryGroup, setCategoryGroup] = useState<ParsedSearchWithNull['categoryGroup']>(
    parsedSearch.current.categoryGroup,
  );
  const [categorySearch, setCategorySearch] = useState<ParsedSearchWithNull['categorySearch']>(
    parsedSearch.current.categorySearch,
  );
  const [isCustomCategory, setCustomCategoryStatus] = useState<ParsedSearchWithNull['isCustomCategory']>(
    !!parsedSearch.current.isCustomCategory || null,
  );

  const [suggestedStyle, setSuggestedStyle] = useState<ParsedSearchWithNull['suggestedStyle']>(
    parsedSearch.current.suggestedStyle || null,
  );
  const [customColors, setCustomColors] = useState<ParsedSearchWithNull['customColors']>(
    parsedSearch.current.customColors || null,
  );

  const [logoOnlyOrPlusWebsite, setLogoOnlyOrPlusWebsite] = useState<ParsedSearchWithNull['logoOnlyOrPlusWebsite']>(
    parsedSearch.current.logoOnlyOrPlusWebsite || null,
  );

  const [websiteId] = useState<ParsedSearchWithNull['websiteId']>(parsedSearch.current.websiteId || null);

  const [jimdoPurpose] = useState<ParsedSearchWithNull['jimdoPurpose']>(parsedSearch.current.jimdoPurpose);

  const masterObject: ParsedSearchWithNull = {
    text,
    icon: icon?.id ? icon.id : parsedSearch.current.icon || null,
    primaryColor,
    secondaryColor,
    font,
    seed,
    suggestionsPage,
    suggestedColors,
    customColors,
    suggestedLayout,
    suggestedStyle,
    style,
    layout,
    suggestedIcon: suggestedIcon?.id ? suggestedIcon.id : parsedSearch.current.suggestedIcon || null,
    iconSearchQuery,
    hasIconSearchResults,
    id,
    logoOnlyOrPlusWebsite,
    websiteId,
    category,
    categoryGroup,
    categorySearch,
    isCustomCategory,
    jimdoPurpose,
  };

  const originalState = Object.keys(masterObject).reduce((state, key) => {
    const elem = masterObject[key as keyof typeof masterObject];

    if (elem !== null && elem !== undefined) {
      (state[key as keyof typeof masterObject] as any) = elem;
    }

    return state;
  }, {} as Partial<ParsedSearch>);

  const getFullQueryString = useCallback(
    () =>
      createSearchParams({
        ...Object.fromEntries(originalQueryParams.current), // this helps retain any query param outside of the logo state
        ...parsedSearch.current,
        ...originalState,
      } as URLSearchParams).toString(),
    [originalState],
  );

  const [fetchIcon] = useIconLazyQuery({
    onCompleted: (data) => {
      setIcon(stripTypeName(data.icon));
    },
  });
  const [fetchSuggestedIcon] = useIconLazyQuery({
    onCompleted: (data) => {
      setSuggestedIcon(stripTypeName(data.icon));
    },
  });

  useEffect(() => {
    if (parsedSearch.current.icon && !icon) {
      fetchIcon({
        variables: {
          id: parsedSearch.current.icon,
        },
      });
    }

    if (parsedSearch.current.suggestedIcon && !suggestedIcon) {
      fetchSuggestedIcon({
        variables: {
          id: parsedSearch.current.suggestedIcon,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isEqual(originalState, parsedSearch.current)) {
      parsedSearch.current = originalState;
      navigate({ search: '?' + getFullQueryString() }, { replace: true });
    }
  }, [getFullQueryString, navigate, originalState]);

  if (!icon && parsedSearch.current.icon) {
    return null;
  }

  if (!suggestedIcon && parsedSearch.current.suggestedIcon) {
    return null;
  }

  return (
    <LogoStateContext.Provider
      value={{
        setText,
        suggestedIcon,
        setSuggestedIcon: (icon) => {
          if (typeof icon === 'string') {
            fetchSuggestedIcon({
              variables: {
                id: icon,
              },
            });
          } else {
            setSuggestedIcon(stripTypeName(icon));
          }
        },
        seed,
        setSeed,
        navigateWithSearch: (route) => {
          navigate(route + '?' + getFullQueryString());
        },
        suggestionsPage,
        setSuggestionsPage,
        suggestedColors,
        setSuggestedColors: (...colors) => {
          setSuggestedColors(colors);
        },
        setCustomColors: (colors) => {
          setCustomColors(colors);
        },
        suggestedLayout,
        suggestedStyle,
        logo: {
          text,
          icon,
          layout,
          primaryColor: customColors ? customColors[0] : primaryColor,
          secondaryColor: customColors ? customColors[1] : secondaryColor,
          font,
          id,
        },
        setSuggestedLayout,
        setSuggestedStyle,
        setStyle,
        style,
        iconSearchQuery,
        setIconSearchQuery,
        setHasIconSearchResults,
        hasIconSearchResults,
        setLogo: (logo) => {
          setIcon(stripTypeName(logo.icon));
          setLayout(logo.layout);
          setPrimaryColor(logo.primaryColor);
          setSecondaryColor(logo.secondaryColor);
          setText(logo.text);
          setFont(logo.font);
          if (logo.id) {
            setId(logo.id);
          }
        },
        customColors,
        locationSearch: '?' + getFullQueryString(),
        logoOnlyOrPlusWebsite,
        setLogoOnlyOrPlusWebsite,
        websiteId,
        category,
        setCategory,
        categoryGroup,
        setCategoryGroup,
        categorySearch,
        setCategorySearch,
        isCustomCategory,
        setCustomCategoryStatus,
        jimdoPurpose,
      }}
    >
      {children}
    </LogoStateContext.Provider>
  );
};
