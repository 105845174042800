import { createContext } from 'react';

export interface LoadingContextValue {
  isLoading: boolean;
}

export interface LoadingContextActions {
  setLoading: (loadingID: string, isLoading: boolean) => void;
}

export const LoadingContext = createContext<LoadingContextValue & LoadingContextActions>({
  isLoading: false,
  setLoading: () => null,
});
