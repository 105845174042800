import { CSSTransition, TransitionGroup } from 'react-transition-group';
import styles from './CategorySuggestionAnimationWrapper.module.scss';
import { cloneElement, lazy, ReactElement } from 'react';
import { useLocation } from 'react-router-dom';
import { routes } from '../../config/routes';
import { useResizeWindow } from '../../common/hooks/useResizeWindow/useResizeWindow';
import classNames from 'classnames';

const CategorySuggestion = lazy(async () => ({
  default: (await import(/* webpackPrefetch: true */ './../CategorySuggestion/CategorySuggestion')).CategorySuggestion,
}));

export const CategorySuggestionAnimationWrapper = () => {
  const location = useLocation();
  const isLargeScreen = useResizeWindow('big');

  return (
    <TransitionGroup
      className={styles.routesGroup}
      childFactory={(child: ReactElement) =>
        cloneElement(child, {
          timeout: location.pathname === routes.ICON && isLargeScreen ? 500 : 0,
        })
      }
    >
      <CSSTransition key={location.pathname} timeout={0} classNames="page-fade">
        <div className={classNames(styles.router, styles.suggestionsRouter)}>
          <CategorySuggestion />
        </div>
      </CSSTransition>
    </TransitionGroup>
  );
};
