import { createContext, useContext } from 'react';

export interface ReportErrorOptions {
  metaData?: object;
}

interface ErrorLoggingActions {
  report: (error: Error, options: ReportErrorOptions) => void;
}

export const ErrorLoggingContext = createContext<ErrorLoggingActions>({} as any);

export const useErrorLoggingContext = () => useContext(ErrorLoggingContext);
