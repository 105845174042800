export function stripTypeName<T>(input: T & { __typename?: string }): T {
  const obj = { ...input };
  if (obj.__typename) delete obj.__typename;
  return obj;
}

export type Nullable<T> = {
  [P in keyof T]: T[P] | null;
};

export type NonNullableProps<T> = {
  [P in keyof T]: NonNullable<T[P]>;
};
