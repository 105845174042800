import { useState, useContext, useEffect, SyntheticEvent } from 'react';
import { routes } from '../../config/routes';
import { track } from '../../common/helpers/tracking/tracking';
import { LogoStateContext } from '../../common/contexts/LogoStateContext/LogoStateContext';
import { BusinessName as UiBusinessName } from './ui/BusinessName/BusinessName';
import { clearWhiteSpaces } from '../../common/helpers/textUtils';

export const BusinessName = () => {
  const { logo, navigateWithSearch, setCategory, setCategoryGroup, setCategorySearch, setCustomCategoryStatus } =
    useContext(LogoStateContext);
  const [inputVal, setInputVal] = useState(logo.text || '');
  const logoState = useContext(LogoStateContext);

  useEffect(() => {
    track({
      trigger: 'show',
      module: 'business-name',
      identifier: 'page',
    });
  }, []);

  const setText = (text: string) => {
    setInputVal(text);
  };

  const onContinue = (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();

    // reset the category search results whenever changing the business name
    setCategory('');
    setCategoryGroup('');
    setCategorySearch('');
    setCustomCategoryStatus(null);

    /* istanbul ignore next */
    track({
      trigger: 'click',
      module: 'business-name',
      identifier: 'continue-button',
      data: {
        businessName: inputVal,
      },
    });

    navigateWithSearch(routes.CATEGORY);
    logoState.setText(clearWhiteSpaces(inputVal));
  };

  return <UiBusinessName text={inputVal} setText={setText} onContinue={onContinue} />;
};
