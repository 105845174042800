import { useEffect, useState } from 'react';
import { isLoggedIn } from '../../helpers/login';
import { signup } from '../../helpers/signup';

interface Props {
  element: JSX.Element;
}
export const AuthenticationContainer = ({ element }: Props) => {
  const [loading, setLoading] = useState(true);
  const [authenticated, setAuthenticated] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      const loggedIn = await isLoggedIn();
      setAuthenticated(loggedIn);
      setLoading(false);
    };
    fetchData();
  }, []);

  if (loading) {
    return null;
  }

  if (!authenticated) {
    signup();
    return null;
  }

  return element;
};
