import { useState } from 'react';
import { Button } from '@jimdo/ui';
import styles from './MoreInfo.module.scss';
import { translations } from './MoreInfo.translations';
import { useLocation } from 'react-router-dom';
import { useCustomTranslations } from '../../hooks/useCustomTranslations/useCustomTranslations';
import { getModuleFromPathname, track } from '../../helpers/tracking/tracking';
import { LogoInformationModal } from '../LogoInformationModal/LogoInformationModal';

export const MoreInfo = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { moreInfo } = useCustomTranslations(translations);
  const location = useLocation();

  /* istanbul ignore next */
  const trackMoreInfoModalOpen = () => {
    track({
      trigger: 'click',
      module: 'app',
      identifier: 'info-cta',
      data: {
        page: getModuleFromPathname(location.pathname),
        analytics: { ingest: false },
      },
    });
  };

  const handleModalOpen = () => {
    trackMoreInfoModalOpen();
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <div className={styles.rightElement}>
        {['/icon', '/layout', '/font', '/color'].includes(location.pathname) && (
          <Button className={styles.moreInfo} buttonStyle="secondary" buttonSize="small" onClick={handleModalOpen}>
            {moreInfo}
          </Button>
        )}
      </div>
      <LogoInformationModal isOpen={isModalOpen} onCloseClick={handleModalClose} />
    </>
  );
};
