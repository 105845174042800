export const translations = (t: TranslationFunction) => ({
  loggedOut: {
    logIn: t('common.profileLogin.loggedOut.signUp', {
      defaultValue: 'Sign In',
    }),
  },
  loggedIn: {
    myLogos: t('common.profileLogin.loggedIn.myLogos', {
      defaultValue: 'Your logos',
    }),
    logOut: t('common.profileLogin.loggedIn.logOut', {
      defaultValue: 'Log Out',
    }),
  },
  termsOfUse: t('common.profileLogin.termsOfUse', {
    defaultValue: '[Terms of Use](https://www.jimdo.com/info/logo-creator-terms)',
  }),
});
