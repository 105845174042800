import { initReactI18next } from 'react-i18next';
import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import XHR from 'i18next-xhr-backend';
import * as Cookies from '@jimdo/jimdo-cookies';

export type AvailableLanguages = 'de' | 'en' | 'es' | 'fr' | 'it' | 'ja' | 'nl';

const languagesWhiteList: AvailableLanguages[] = ['de', 'en', 'es', 'fr', 'it', 'ja', 'nl'];

export const i18n = i18next.use(XHR).use(LanguageDetector).use(initReactI18next);

i18n.init({
  backend: {
    loadPath: `/locales/{{lng}}/{{ns}}.json`,
  },
  load: 'languageOnly',
  supportedLngs: languagesWhiteList,
  detection: {
    lookupCookie: 'lang',
  },
});

i18n.on('languageChanged', (lng) => {
  document.documentElement.setAttribute('lang', lng);
  Cookies.set('lang', lng);
});
