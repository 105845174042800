import { PropsWithChildren, ReactNode } from 'react';
import styles from './LayoutContent.module.scss';
import classNames from 'classnames';

interface Props {
  children: ReactNode;
  className?: string;
}

export const LayoutContent = ({ children, className }: PropsWithChildren<Props>) => (
  <div className={classNames(styles.root, className)}>{children}</div>
);
