import { useEffect, useRef, useMemo } from 'react';
import { trackPageMetaData, trackPageView } from '../../helpers/tracking/trackGtmEvents';
import { useLocation } from 'react-router-dom';

export const PageViewTracker = () => {
  const currentLogoView = useRef('/');
  const isFirstRender = useRef(true);
  const location = useLocation();

  const currentPagepath = useMemo(() => {
    if (location && location.search) {
      return `${location.pathname}${location.search}`;
    }
    return location ? location.pathname : '';
  }, [location]);

  useEffect(() => {
    if (!isFirstRender.current && location && currentLogoView.current !== location.pathname) {
      trackPageView(currentPagepath);
      currentLogoView.current = location.pathname;
    }
  }, [currentPagepath, location]);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      trackPageMetaData(currentPagepath);
    }
  }, [currentPagepath]);

  return null;
};
