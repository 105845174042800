import { Topbar as UITopbar } from '../../ui/Topbar/Topbar';
import { useLocation } from 'react-router-dom';
import { routes } from '../../../config/routes';
import { ProfileLogin } from '../ProfileLogin/ProfileLogin';
import { MoreInfo } from '../../ui/MoreInfo/MoreInfo';

const isEditorMode = (route: string) => [routes.LAYOUT, routes.ICON, routes.FONT, routes.COLOR].includes(route);

export const Topbar = () => {
  const location = useLocation();
  const { pathname } = location;

  return (
    <UITopbar
      leftElement={isEditorMode(pathname) && <ProfileLogin />}
      rightElement={isEditorMode(pathname) && <MoreInfo />}
    />
  );
};
