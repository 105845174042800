import { useContext } from 'react';
import { LogoStateContext, LogoStateActions } from '../../contexts/LogoStateContext/LogoStateContext';

export const getLogoUIState = (logoState: LogoStateActions) => {
  return {
    iconId: logoState.suggestedIcon!.id,
    layout: logoState.suggestedLayout!,
    primaryColor: logoState.suggestedColors![0],
    secondaryColor: logoState.suggestedColors![1],
    seed: logoState.seed,
    style: logoState.suggestedStyle!,
    selectedStyle: logoState.style!,
    iconSearchQuery: logoState.iconSearchQuery,
    ...(logoState.customColors && { customPrimaryColor: logoState.customColors[0] }),
    ...(logoState.customColors && { customSecondaryColor: logoState.customColors[1] }),
  };
};

export const useLogoUIState = () => {
  const logoState = useContext(LogoStateContext);

  return getLogoUIState(logoState);
};
