import { useState, useCallback, useEffect } from 'react';

type windowSize = 'big' | 'small';

export const useResizeWindow = (size: windowSize) => {
  const matchQuery =
    size === 'big' ? window.matchMedia('(min-width: 1024px)') : window.matchMedia('(max-width: 1023px)');

  const [isResized, setIsResized] = useState(matchQuery.matches);

  const handler = useCallback(() => {
    setIsResized(matchQuery.matches);
  }, [matchQuery]);

  useEffect(() => {
    window.addEventListener('resize', handler);

    return () => window.removeEventListener('resize', handler);
  }, [handler]);

  return isResized;
};
