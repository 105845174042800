import { PropsWithChildren, useEffect, useState } from 'react';
import { IconButton, OneLineInput, iconEdit, iconClear, useStyles, FlexBox, Popover, UnstyledButton } from '@jimdo/ui';
import { useFocus } from '../../hooks/useFocus/useFocus';
import { useResizeWindow } from '../../hooks/useResizeWindow/useResizeWindow';
import { isTextInvalid, clearWhiteSpaces } from '../../helpers/textUtils';
import { track } from '../../helpers/tracking/tracking';
import { useCustomTranslations } from '../../hooks/useCustomTranslations/useCustomTranslations';
import { translations } from './BusinessNameInput.translations';
import styles from './BusinessNameInput.module.scss';

interface Props {
  text: string;
  setText: (text: string) => void;
  className?: string;
}

export const BusinessNameInput = ({ text, setText, className }: PropsWithChildren<Props>) => {
  const { inputPlaceholder, invalidCharsErrorMessage, clearInput, inputTitle, tooltip } =
    useCustomTranslations(translations);
  const [isTooltipVisible, setTooltipVisibility] = useState(false);
  const isInputValEmpty = text.length === 0;
  const isInputInvalid = isTextInvalid(text);
  const errorMessage = isInputInvalid && !isInputValEmpty ? invalidCharsErrorMessage : undefined;
  const [hasTrackedLimit, stopTrackingInputLimit] = useState(false);
  const [inputRef, setFocus] = useFocus<HTMLInputElement>();
  const isLargeScreen = useResizeWindow('big');
  const rootClasses = useStyles({ marginBottom: 'l' }, className);
  const MAX_INPUT_LENGTH = 30;

  const hitLimit = text.length >= MAX_INPUT_LENGTH;
  if (hitLimit && !hasTrackedLimit) {
    stopTrackingInputLimit(true);
    track({
      trigger: 'error',
      module: 'business-name',
      identifier: 'character-limit',
      data: {
        analytics: {
          ingest: false,
        },
      },
    });
  }

  useEffect(() => {
    isLargeScreen && setFocus();
  }, [isLargeScreen, setFocus]);

  // use separate state variable to control tooltip visibility so that it shows after mount and prevents error
  useEffect(() => {
    setTooltipVisibility(text.length === MAX_INPUT_LENGTH);
  }, [text.length]);

  const getInputDecoration = () => {
    if (isInputValEmpty) {
      return <IconButton buttonStyle="primary" className={styles.editIcon} icon={iconEdit} type="button" />;
    }

    return (
      <FlexBox alignItems="center" justifyContent="center">
        <Popover
          preferredPosition="top"
          content={tooltip}
          isVisible={isTooltipVisible}
          onIsVisibleChange={setTooltipVisibility}
        >
          <UnstyledButton className={styles.counter}>
            {text.length}/{MAX_INPUT_LENGTH}
          </UnstyledButton>
        </Popover>
        <IconButton
          buttonStyle="primary"
          className={styles.closeIcon}
          icon={iconClear}
          onClick={() => {
            setText('');
            isLargeScreen && setFocus();
          }}
          title={clearInput}
          type="reset"
        />
      </FlexBox>
    );
  };

  return (
    <div className={rootClasses}>
      <OneLineInput
        title={inputTitle}
        ref={inputRef}
        className={styles.input}
        value={text}
        onChange={(e) => setText(clearWhiteSpaces(e.currentTarget.value, true))}
        placeholder={inputPlaceholder}
        inputAdornment={<FlexBox alignItems="center">{getInputDecoration()}</FlexBox>}
        maxLength={MAX_INPUT_LENGTH}
        autoFocus={isLargeScreen}
        errorMessage={errorMessage}
        data-e2e="business-name"
      />
    </div>
  );
};
