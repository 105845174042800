import { createContext } from 'react';
import { Layout, Style, Icon as GraphqlGeneratedIcon, Font } from '../../../__generated__/graphql';
import { Nullable } from '../../helpers/utils';

export type Colors = [string, string];

export interface Logo {
  id?: string | null;
  text: string;
  icon: Icon;
  primaryColor: string;
  secondaryColor: string;
  font: Font;
  layout: Layout;
}

export interface Icon extends GraphqlGeneratedIcon {
  id: string;
}

export type LogoOnlyOrPlusWebsiteOption = 'logoOnly' | 'logoPlusWebsite';

export interface LogoStateActions extends State {
  setText: (text: string) => void;
  navigateWithSearch: (url: string) => void;
  setSuggestionsPage: (val: number) => void;
  setSuggestedLayout: (layout: Layout) => void;
  setSuggestedColors: (...suggestedColors: Colors) => void;
  setCustomColors: (customColors: Colors | null) => void;
  setSuggestedIcon: (icon: Icon | string) => void;
  setSuggestedStyle: (style: Style) => void;
  setLogo: (logo: Logo) => void;
  setStyle: (style: Style) => void;
  setIconSearchQuery: (query: string | null) => void;
  setHasIconSearchResults: (hasResults: boolean | null) => void;
  setSeed: (seed: number) => void;
  setLogoOnlyOrPlusWebsite: (option: LogoOnlyOrPlusWebsiteOption) => void;
  setCategory: (category: string) => void;
  setCategoryGroup: (categoryGroup: string) => void;
  setCategorySearch: (search: string) => void;
  setCustomCategoryStatus: (isCustom: true | null) => void;
}

export interface State {
  seed: number;
  suggestionsPage: number;
  suggestedLayout: Layout | null;
  suggestedColors: Colors | null;
  suggestedIcon: Icon | null;
  suggestedStyle: Style | null;
  logo: Nullable<Logo>;
  style: Style | null;
  customColors: Colors | null;
  iconSearchQuery: string | null;
  hasIconSearchResults: boolean | null;
  locationSearch: string;
  logoOnlyOrPlusWebsite: LogoOnlyOrPlusWebsiteOption | null;
  websiteId: string | null;
  category?: string | null;
  categoryGroup?: string | null;
  categorySearch?: string | null;
  isCustomCategory?: boolean | null;
  jimdoPurpose?: 'website' | 'onlineStore' | 'logoOnly' | null;
}

export const LogoStateContext = createContext<LogoStateActions>({} as unknown as LogoStateActions);
