import { PropsWithChildren, ReactNode } from 'react';
import { track } from '../../helpers/tracking/tracking';
import { TrackingContext } from './TrackingContext';

export interface OwnProps {
  children: ReactNode;
}

export const TrackingContextProvider = (props: PropsWithChildren<OwnProps>) => {
  return (
    <TrackingContext.Provider
      value={{
        track,
      }}
    >
      {props.children}
    </TrackingContext.Provider>
  );
};
