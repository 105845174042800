import { createContext } from 'react';

export interface Props {
  isModalOpen: boolean;
  setIsModalOpen: (isModalOpen: boolean) => void;
}

export const defaultValues = {
  isModalOpen: false,
  setIsModalOpen: () => null,
};

export const EditBusinessNameModalContext = createContext<Props>(defaultValues);
