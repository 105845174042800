import { ApolloClient, ApolloLink, InMemoryCache, defaultDataIdFromObject } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { login } from '../common/helpers/login';
import generatedIntrospection from '../__generated__/graphql';
import type { InMemoryCacheConfig } from '@apollo/client';
import type { StrictTypedTypePolicies } from '../__generated__/apollo-helpers';

// We generate "possibleTypes" following this - https://the-guild.dev/graphql/codegen/plugins/other/fragment-matcher#how-to-use
export const possibleTypes: InMemoryCacheConfig['possibleTypes'] = generatedIntrospection.possibleTypes;

const merge = (existing: any, incoming: any) => ({ ...existing, ...incoming });

export const typePolicies: StrictTypedTypePolicies = {
  Query: {
    fields: {
      colors: {
        merge,
      },
      colorShades: {
        merge,
      },
      layouts: {
        merge,
      },
      websitePreview: {
        merge,
      },
    },
  },
};

const cache = new InMemoryCache({
  possibleTypes,
  typePolicies,
  dataIdFromObject(responseObject) {
    return defaultDataIdFromObject(responseObject);
  },
});

export const apolloClient = new ApolloClient({
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.map(({ message, locations, path }) =>
          console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`),
        );
      if (networkError) console.log(`[Network error]: ${networkError}`);
    }),
    new BatchHttpLink({
      fetch: async (input, init) => {
        const response = await fetch(input, { ...(init || {}), credentials: 'include' });
        if (response.status === 401) {
          login();
        }
        return response;
      },
      uri: '/graphql',
    }),
  ]),
  cache,
});
