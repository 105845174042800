import { RolloutContext, rolloutDefaults } from './RolloutContext';
import { Rollouts } from '../../../__generated__/graphql';
import { PropsWithChildren } from 'react';

export interface Props {
  rollouts: Omit<Rollouts, '__typename'>;
}

export const RolloutProvider = ({ children, rollouts }: PropsWithChildren<Props>) => {
  if (!Object.entries(rollouts).length) {
    return <RolloutContext.Provider value={rolloutDefaults}>{children}</RolloutContext.Provider>;
  }

  return <RolloutContext.Provider value={rollouts}>{children}</RolloutContext.Provider>;
};
