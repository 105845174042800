import { useLazyQuery, LazyQueryHookOptions, QueryLazyOptions, LazyQueryResult } from '@apollo/client';
import { useErrorLoggingContext } from '../../contexts/ErrorLoggingContext/ErrorLoggingContext';
import { DocumentNode } from 'graphql';
import type { OperationVariables } from '@apollo/client';
import { useContext } from 'react';
import { SnackbarContext } from '../../contexts/SnackbarContext/SnackbarContext';
import { translations } from '../../../common.translations';
import { useCustomTranslations } from '../useCustomTranslations/useCustomTranslations';

type QueryResultUnsuccessful<D, V extends OperationVariables> = Omit<LazyQueryResult<D, V>, 'data'> & {
  success: false;
  data: undefined;
};

type QueryResultSuccessful<D, V extends OperationVariables> = Omit<LazyQueryResult<D, V>, 'data'> & {
  success: true;
  data: D;
};

type QueryResultUnion<D, V extends OperationVariables> = QueryResultUnsuccessful<D, V> | QueryResultSuccessful<D, V>;

export interface Options<D, V extends OperationVariables> extends LazyQueryHookOptions<D, V> {
  customErrorMessage?: string;
  noSnackbarOnError?: boolean;
}

export function useCustomLazyQuery<D, V extends OperationVariables>(
  query: DocumentNode,
  options: Options<D, V> = {},
): [(options?: QueryLazyOptions<V> | undefined) => void, QueryResultUnion<D, V>] {
  const { report } = useErrorLoggingContext();
  const { pushMessage } = useContext(SnackbarContext);
  const { queryErrorGeneral } = useCustomTranslations(translations);

  const { customErrorMessage, noSnackbarOnError, onError, ...lazyQueryHookOptions } = options;

  const [lazyQuery, lazyQueryHookResult] = useLazyQuery<D, V>(query, {
    ...lazyQueryHookOptions,
    notifyOnNetworkStatusChange: true,
    onError: (error) => {
      /* istanbul ignore next line */
      report(error || new Error('No data received'), {
        metaData: {
          data,
          query,
          variables: options.variables,
        },
      });
      if (!noSnackbarOnError) {
        pushMessage({ message: customErrorMessage || queryErrorGeneral, type: 'danger' });
      }
      if (onError) {
        onError(error);
      }
    },
  });

  const { data, error, networkStatus, called } = lazyQueryHookResult;

  if (networkStatus === 1 || !called || (!error && !data)) {
    return [
      lazyQuery,
      {
        ...lazyQueryHookResult,
        success: false,
        data: undefined,
      },
    ];
  }

  if (error || !data) {
    return [
      lazyQuery,
      {
        ...lazyQueryHookResult,
        success: false,
        data: undefined,
      },
    ];
  }

  return [
    lazyQuery,
    {
      ...lazyQueryHookResult,
      success: true,
      data,
    },
  ];
}
