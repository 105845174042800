import { createContext } from 'react';
import { Rollouts } from '../../../__generated__/graphql';

export const rolloutDefaults: Rollouts = {
  features: {
    abtasty: false,
    logoWebsiteSplitIsLogoLeft: false,
    logoWebsiteSplit: false,
    businessCategory: false,
    usercentrics: false,
  },
  crossProjectFlags: {
    dolphinFullstory: false,
  },
};

export const RolloutContext = createContext<Rollouts>(rolloutDefaults);
