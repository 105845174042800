import { createContext } from 'react';
import { Config } from '../../../__generated__/graphql';

export const ConfigContext = createContext<Config>({
  dashWebsitesUrl: '',
  cmsWizardUrl: '',
  logosUrl: '',
  logoPngPreviewUrl: '',
  buyWebsiteSubscriptionUrl: '',
});
