import { Children, PropsWithChildren, ReactNode } from 'react';
import classNames from 'classnames';
import { Box } from '@jimdo/ui';
import styles from './ProfileMenu.module.scss';

interface Props {
  children: ReactNode | ReactNode[];
  className?: string;
}

export const ProfileMenu = ({ children, className }: PropsWithChildren<Props>) => {
  return (
    <Box
      component="ul"
      paddingTop="xs"
      paddingBottom="xs"
      borderRadius="systemBorderRadiusDefault"
      backgroundColor="systemColorGrey-000"
      boxShadow="systemShadowSecondary"
      className={classNames(styles.root, className)}
    >
      {Children.map(children, (menuItem) => (
        <li className={styles.item}>{menuItem}</li>
      ))}
    </Box>
  );
};
