import { PropsWithChildren, useState } from 'react';
import { EditBusinessNameModalContext } from './EditBusinessNameModalContext';

export const EditBusinessNameModalContextProvider = ({ children }: PropsWithChildren<unknown>) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <EditBusinessNameModalContext.Provider value={{ isModalOpen, setIsModalOpen }}>
      {children}
    </EditBusinessNameModalContext.Provider>
  );
};
