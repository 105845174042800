import { translations } from './ProfileLogin.translations';
import { useCustomTranslations } from '../../hooks/useCustomTranslations/useCustomTranslations';
import { ProfileLogin as UIProfileLogin } from '../../ui/ProfileLogin/ProfileLogin';
import { useProfileLoginQuery } from '../../../__generated__/graphql';

export const ProfileLogin = () => {
  const { login } = useCustomTranslations(translations);
  const queryResult = useProfileLoginQuery({
    fetchPolicy: 'no-cache',
  });

  if (!queryResult.success) {
    return <UIProfileLogin label={login} isLoggedIn={false} />;
  }

  const loggedInUser = queryResult.data?.user;

  return <UIProfileLogin label={login} isLoggedIn={!!loggedInUser} />;
};
