import { DocumentNode, GraphQLError } from 'graphql';

export interface MockResponse<D, V> {
  request: {
    query: DocumentNode;
    variables?: V;
  };
  result: {
    data?: D;
    errors?: GraphQLError[];
  };
}

export interface MockOptions<D, V> {
  data?: D;
  variables?: V;
  error?: GraphQLError;
}

export function getMock<D, V>(query: DocumentNode) {
  return function ({ data, variables, error }: MockOptions<D, V>): MockResponse<D, V> {
    return {
      request: {
        query: query,
        variables,
      },
      result: {
        data,
        errors: error ? [error] : undefined,
      },
    };
  };
}
